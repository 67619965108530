
import { _getURL } from "@/api/index";
import { useState, useRef } from "react";
import { reduce } from 'lodash';
import { FaPlay, FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation, EffectFade } from "swiper";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "@/helpers/common_helper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

const featureData = [
    {
        text: "Football Chatroom",
        contentImage: "/assets/images/other/landingPage/landing_page_feature_chatroom.png",
        contentTitle: "Football Chatroom",
        contentDescription: "“Connect and Discuss the Beautiful Game”",
        contentImageLeft: "Join the ultimate football chatroom and connect with fans from around the world.",
        contentImageRight: [
            { contentText: "Match predictions" },
            { contentText: "Team and player performances debate" },
            { contentText: "Chit-chat" }
        ]
    },
    {
        text: "Match & Stats",
        contentImage: "/assets/images/other/landingPage/landing_page_feature_stats.png",
        contentTitle: "Match & Stats",
        contentDescription: "“Live Scores and Statistics”",
        contentImageLeft: "Stay on top of every football match with comprehensive statistics. Get real-time scores, updates, and in-depth stats for your favourite teams and players.",
        contentImageRight: [
            { contentText: "Live scores" },
            { contentText: "Match analysis" },
            { contentText: "Competition info" },
            { contentText: "Team profile" },
            { contentText: "Player profile" }
        ]
    },
    {
        text: "Join Fan Club",
        contentImage: "/assets/images/other/landingPage/landing_page_feature_profile.png",
        contentTitle: "Join Fan Club",
        contentDescription: "“Unite and Celebrate Your Team”",
        contentImageLeft: "Join a dedicated football fan club with passionate community of loyal supporters, show your unwavering support and immerse yourself in the world of your favourite team.",
        contentImageRight: [
            { contentText: "Super fan club ID" },
            { contentText: "Fan club event" },
            { contentText: "Football EXPO" },
        ]
    },
]

const FeatureTabSection = (props) => {
    const [featureActive, setFeatureActive] = useState(1)
    const [swiper, setSwiper] = useState(null);

    // {
    //     isMobileView == true ? 
    //     <Swiper
    //         slidesPerView={'auto'}
    //         spaceBetween={0}
    //         className={"award-best-casino-swiper"}
    //         pagination={true} 
    //         modules={[Pagination]}
    //         centerInsufficientSlides={true}
    //         breakpoints={{
    //             320: {
    //                 slidesPerView: 1,
    //             },
    //             375: {
    //               slidesPerView: 2,
    //             }
    //         }}
    //     >
    //         {
    //             _.reduce(awardList, (result, value, key) => {
    //                 result.push(
    //                     <SwiperSlide>
    //                         <a key={key} href={value.custom_url} target='_blank' className='text-decoration-none text-black'>
    //                             <div className='award-best-casino-item-outer'>
    //                                 <div key={key} className='award-best-casino-item'>
    //                                     <div className='award-best-casino-item-image'>
    //                                         <img src={`${process.env.NEXT_PUBLIC_FS_URL}${value.icon}`} alt="" className="img-fluid w-100"/> 
    //                                     </div>
    //                                 </div>
    //                                 <h4 className='custom_h4'>
    //                                     {value.award_name}
    //                                 </h4>
    //                             </div>
    //                         </a>
    //                     </SwiperSlide>
    //                 )
    //                 return result;
    //             }, [])
    //         }
    //     </Swiper>
    //     :
    //     _.reduce(awardList, (result, value, key) => {
    //         result.push(
    //             <Link key={key} href={value.custom_url} legacyBehavior={true}>
    //                 <a target='_blank' className='m-0 text-black text-decoration-none'>
    //                     <div className='award-best-casino-item-outer m-0 w-100'>
    //                         <div key={key} className='award-best-casino-item'>
    //                             <div className='award-best-casino-item-image'>
    //                                 <img src={`${process.env.NEXT_PUBLIC_FS_URL}${value.icon}`} alt="" className="img-fluid w-100" style={{ borderRadius: '15px' }}/> 
    //                             </div>
    //                         </div>
    //                         <h4 className='custom_h4'>
    //                             {value.award_name}
    //                         </h4>
    //                     </div>
    //                 </a>
    //             </Link>
    //         )
    //         return result;
    //     }, [])
    // }

    return (
        <>
            <div className="landing_page__sectionAppFeatures_tabRow">
                {
                    reduce(featureData, (result, value, key) => {
                        result.push(
                            <div
                                onClick={() => { swiper.slideTo(key + 1) }}
                                key={key}
                                className="landing_page__fs17 landing_page__fontBold landing_page__fontColorPrimary landing_page__sectionAppFeatures_tabBox"
                                style={{
                                    backgroundColor: key == featureActive ? '#00b15a' : 'transparent',
                                    border: key == featureActive ? 'transparent' : "solid 1px white"
                                }}
                            >
                                {value.text}
                            </div>
                        )
                        return result;
                    }, [])
                }
            </div>
            {/* <div className="landing_page__swiper_navigation">
                <div className={"swiper-button-prev landing_page__swiper_button_prev"}>
                    <FaCaretLeft className="landing_page__swiper_button_icon" />
                </div>
                <div className={"swiper-button-next landing_page__swiper_button_next"}>
                    <FaCaretRight className="landing_page__swiper_button_icon" />
                </div>
            </div> */}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
                <div className="landing_page__swiper_navigation">
                    <div className={"swiper-button-prev landing_page__swiper_button_prev"}>
                        <FaCaretLeft className="landing_page__swiper_button_icon" />
                    </div>
                    <div className={"swiper-button-next landing_page__swiper_button_next"}>
                        <FaCaretRight className="landing_page__swiper_button_icon" />
                    </div>
                </div>
                <div className="landing_mobilePage__sectionAppFeature_swiperBg landing_mobilePage__sectionAppFeature_swiperLeftBg" />
                <div className="landing_mobilePage__sectionAppFeature_swiperBg landing_mobilePage__sectionAppFeature_swiperRightBg" />
                <Swiper
                    slidesPerView={1}
                    modules={[Autoplay, Navigation, EffectFade]}
                    onSwiper={setSwiper}
                    onSlideChange={(swiper) => { setFeatureActive(swiper.realIndex) }}
                    className={"landing_page__sectionAppFeatures_swiperList"}
                    loop={true}
                    // autoplay={false}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    effect="fade"
                    speed={1000}
                    navigation={{
                        prevEl: ".landing_page__swiper_button_prev",
                        nextEl: ".landing_page__swiper_button_next"
                    }}
                >
                    <div className="swiper-wrapper">
                        {
                            reduce(featureData, (result, value, key) => {
                                result.push(
                                    <SwiperSlide key={key}>
                                        <div className="landing_page__sectionAppFeatures_contentBox" style={{ backgroundColor: '#000' }}>
                                            <div className="landing_page__fs40 landing_mobilePage__fs74 landing_page__fontBold landing_page__fontColorPrimary">
                                                {value.contentTitle}
                                            </div>
                                            <div className="landing_page__fs17 landing_mobilePage__fs35 landing_page__fontRegular landing_page__fontColorPrimary landing_page__sectionAppFeatures_contentBox_contentDescription">
                                                {value.contentDescription}
                                            </div>
                                            <div className="landing_page__sectionAppFeatures_contentBox_imageBox">
                                                <div className="landing_page__sectionAppFeatures_contentBox_imageBox_contentLeft">
                                                    <div className="landing_page__fs15 landing_page__fontRegular landing_page__fontColorPrimary landing_page__sectionAppFeatures_contentBox_imageBox_contentLeft_description">
                                                        {value.contentImageLeft}
                                                    </div>
                                                    <div className="landing_page__featureCircle">
                                                        <div className="landing_page__featureCircleInner" />
                                                    </div>
                                                </div>
                                                <div className="landing_page__sectionAppFeatures_contentBox_imageBox_contentImage">
                                                    <img className="w-100" src={value.contentImage} />
                                                </div>
                                                <div className="landing_page__sectionAppFeatures_contentBox_imageBox_contentRight">
                                                    {
                                                        reduce(value.contentImageRight, (result2, value2, key2) => {
                                                            result2.push(
                                                                <div key={key2} className="landing_page__sectionAppFeatures_contentBox_imageBox_contentRight_row">
                                                                    <div className="landing_page__featureCircle">
                                                                        <div className="landing_page__featureCircleInner" />
                                                                    </div>
                                                                    <div className="landing_page__fs15 landing_page__fontRegular landing_page__fontColorPrimary landing_page__sectionAppFeatures_contentBox_imageBox_contentRight_row_description">
                                                                        {value2.contentText}
                                                                    </div>
                                                                </div>
                                                            )
                                                            return result2;
                                                        }, [])
                                                    }
                                                </div>
                                            </div>
                                            <div className="landing_page__fs17 landing_mobilePage__fs35 landing_page__fontRegular landing_page__fontColorPrimary landing_page__sectionAppFeatures_contentBox_contentDescription landing_mobilePage__sectionAppFeatures_contentBox_contentDescription">
                                                {value.contentImageLeft}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                                return result;
                            }, [])
                        }
                    </div>
                </Swiper>
            </div>
        </>
    )
}

export default function Installer({ device }) {
    const [scrollTop, setScrollTop] = useState(0);
    const appGetStart = useRef(null);
    const appGetStartMobile = useRef(null);
    const appShowcase = useRef(null);
    const appDownload = useRef(null)
    const isMobileView = useMediaQuery('(max-width:767px)');

    // return (
    //     <>
    //         {/* <!--------- Main area start ---------> */}
    //         <main className="main">
    //             {
    //                 device == "mobile"
    //                     ?
    //                     <section className="hero__area">
    //                         <div className="container">
    //                             <div className="row">
    //                                 <div className="hero__mobile">
    //                                     <div className="hero__left__blk">
    //                                         <img src="/assets/images/phone-frame.png" />
    //                                         <div className="hero__phone_screen">
    //                                             <img src="/assets/images/phone-screen.png?v=2" />
    //                                         </div>
    //                                     </div>
    //                                     <div className="hero__right__blk">
    //                                         <h1>优质的足球资讯</h1>
    //                                         <h1>第一时间了解赛果战报</h1>
    //                                         <div className="hero__download_section">
    //                                             <div className="hero__store_list">
    //                                                 <div className="store-download">
    //                                                     <a target="_blank" href="https://ufootball.co/android-app">Android 下载</a>
    //                                                     <img src="/assets/images/android.png" />
    //                                                 </div>
    //                                                 <div className="store-download">
    //                                                     <a target="_blank" href="https://ufootball.co/ios-app-test">iPhone 下载</a>
    //                                                     <img src="/assets/images/ios.png" />
    //                                                 </div>
    //                                                 <div className="store-download">

    //                                                     <a target="_blank" href={"https://www.ufootballmy.com/Ufootball.apk?v=" + Date.now()}>APK 下载</a>
    //                                                     <img src="/assets/images/android.png" />
    //                                                 </div>
    //                                             </div>
    //                                             {/* <div className="hero__qrcode">
    //                                         <div className="qrcode-frame">
    //                                             <img src="/assets/images/qr-code.png" />
    //                                             <span>扫码下载apk</span>
    //                                         </div>
    //                                     </div> */}
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </section>
    //                     :
    //                     <section className="hero__area">
    //                         <div className="container">
    //                             <div className="row">
    //                                 <div className="col-lg-5">
    //                                     <div className="hero__left__blk">
    //                                         <img src="/assets/images/phone-frame.png" />
    //                                         <div className="hero__phone_screen">
    //                                             <img src="/assets/images/phone-screen.png?v=2" />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="col-lg-7">
    //                                     <div className="hero__right__blk">
    //                                         <h1>优质的足球资讯</h1>
    //                                         <h1>第一时间了解赛果战报</h1>
    //                                         <div className="hero__download_section">
    //                                             <div className="hero__store_list">
    //                                                 <div className="store-download">
    //                                                     <a target="_blank" href="https://ufootball.co/android-app">Android 下载</a>
    //                                                     <img src="/assets/images/android.png" />
    //                                                 </div>
    //                                                 <div className="store-download">
    //                                                     <a target="_blank" href="https://ufootball.co/ios-app-test">iPhone 下载</a>
    //                                                     <img src="/assets/images/ios.png" />
    //                                                 </div>
    //                                                 <div className="store-download">
    //                                                     <a target="_blank" href="https://ufootball.co/ios-app-test">IPad 下载</a>
    //                                                     <img src="/assets/images/ipad.png" />
    //                                                 </div>
    //                                             </div>
    //                                             <div className="hero__qrcode">
    //                                                 <div className="qrcode-frame">
    //                                                     <img src="/assets/images/qr-code.png?v=5" />
    //                                                     <span>扫码下载APK</span>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </section>
    //             }
    //             {/* <!--------- Hero area end ---------> */}

    //             {/* <!--------- Partners area start ---------> */}
    //             <section className="partner__area">
    //                 <div className="partner__wrap">
    //                     <div className="container">
    //                         <div className="row">
    //                             <div className="col-lg-12">
    //                                 <div className="partner__title">
    //                                     <h2>官方合作伙伴</h2>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="row">
    //                             <div className="partner__list">
    //                                 <a href="https://www.ucreative.com.my/" target="_blank" className="partner__single__blk">
    //                                     <img src="/assets/images/partner1.png" />
    //                                     <h3>U Creative</h3>
    //                                     <span>广告营销</span>
    //                                 </a>
    //                                 <a href="https://www.ucreation.co/" target="_blank" className="partner__single__blk">
    //                                     <img src="/assets/images/partner2.png" />
    //                                     <h3>UCreation</h3>
    //                                     <span>电竞主办</span>
    //                                 </a>
    //                                 <a href="https://fitpie.co/" target="_blank" className="partner__single__blk">
    //                                     <img src="/assets/images/partner3.png" />
    //                                     <h3>FitPie</h3>
    //                                     <span>健康瘦身</span>
    //                                 </a>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </section>
    //             {/* <!--------- Partners area end ---------> */}
    //         </main>
    //         {/* <!--------- Main area end ---------> */}
    //     </>
    // )

    const handleScrollClick = (scrollTo) => {
        let scrollToY = 0;
        if (appGetStart.current && scrollTo == "appGetStart") {
            scrollToY = appGetStart.current.getBoundingClientRect().top + window.pageYOffset;
        } else if (appGetStartMobile.current && scrollTo == "appGetStartMobile") {
            scrollToY = appGetStartMobile.current.getBoundingClientRect().top + window.pageYOffset;
        } else if (appShowcase.current && scrollTo == "appShowcase") {
            scrollToY = appShowcase.current.getBoundingClientRect().top + window.pageYOffset;
        } else if (appDownload.current && scrollTo == "appDownload") {
            scrollToY = appDownload.current.getBoundingClientRect().top + window.pageYOffset;
        }
        if (scrollToY > 0) {
            window.scrollTo({ top: scrollToY, behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="landing_page__backgroundColorPrimary landing_page__body">
                <div className="landing_page__container">
                    <div
                        onClick={() => { handleScrollClick("appDownload") }}
                        className="landing_page__fs22 landing_page__fontBold landing_page__fontColorPrimary landing_page__button"
                    >
                        Download App
                    </div>
                    <div className="landing_page__sectionAppHeaderBackground" />
                    <div className="landing_page__sectionContent">
                        <div className="landing_page__sectionAppHeader landing_page__containerSection">
                            <div className="landing_page__sectionAppHeader_logo">
                                <img className="w-100" src="/assets/images/other/landingPage/landing_page_logo.png" />
                            </div>
                        </div>
                        <div className="landing_mobilePage__sectionAppHeader" >
                            <div className="landing_mobilePage__sectionAppHeader_logo">
                                <img className="w-100" src="/assets/images/other/landingPage/landing_page_m_logo.png" />
                            </div>
                        </div>
                        <section className="landing_page__sectionApp ">
                            <div className="landing_page__containerSection d-flex justify-content-center align-items-center flex-column">
                                <div className="landing_page__fs68 landing_mobilePage__fs96 landing_page__fontBold landing_page__fontColorPrimary landing_page__sectionApp_title">
                                    Football app <br className="d-block d-md-none" /> for all fans
                                </div>
                                <div className="landing_page__fs22 landing_mobilePage__fs35 landing_page__fontRegular landing_page__fontColorPrimary landing_page__sectionApp_content">
                                    We provide premium football content and products that enhance fan experiences and shape the future of football.
                                </div>
                                <div className="landing_page__sectionApp_contentButtonRow">
                                    <div onClick={() => { handleScrollClick(isMobileView ? "appGetStartMobile" : "appGetStart") }} className="landing_page__fs22 landing_mobilePage__fs35 landing_page__fontBold landing_page__fontColorPrimary landing_page__buttonSecondary landing_page__buttonSecondary1">
                                        Get Started
                                    </div>
                                    <div onClick={() => { handleScrollClick("appShowcase") }} className="landing_page__fs22 landing_mobilePage__fs35 landing_page__fontBold landing_page__fontColorPrimary landing_page__buttonSecondary landing_page__buttonSecondary2">
                                        <FaPlay className="landing_page__playIcon" /> Watch Video
                                    </div>
                                </div>
                            </div>
                            <div className="w-100" ref={appGetStartMobile} >
                                <div className="w-100 d-none d-md-block landing_page__containerSection">
                                    <div className="landing_page__containerSection_phoneBg">
                                        <div className="landing_page__containerSection_phone" ref={appGetStart} />
                                    </div>
                                </div>
                                <div className="w-100 d-block d-md-none">
                                    <img className="w-100" src="/assets/images/other/landingPage/landing_page_m_phone.png" />
                                </div>
                            </div>
                        </section>
                        <div className="landing_page__containerSection">
                            <section
                                ref={appShowcase}
                                className="landing_page__backgroundColorThird landing_page__sectionAppShowcase "
                            >
                                <div className="landing_page__fs22 landing_mobilePage__fs23 landing_page__fontBold landing_page__fontColorSecondary landing_page__backgroundColorSecondary landing_page__sectionTitle" >
                                    APP SHOWCASE
                                </div>
                                <div className="landing_page__fs32 landing_mobilePage__fs74 landing_page__fontBold landing_page__fontColorPrimary landing_page__sectionAppShowcaseDescription">
                                    Discover a world of football at your fingertips.
                                </div>
                                <div style={{ margin: 50 }}>
                                    <iframe className="landing_page_youtube" src="https://www.youtube.com/embed/PPeWjeK_OrI?si=fMajiLDETIf3xHG-" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>

                                    </iframe>
                                </div>
                            </section>
                        </div>
                        <section className="landing_page__sectionAppFeatures">
                            <div className="landing_page__fs22 landing_mobilePage__fs23 landing_page__fontBold landing_page__fontColorSecondary landing_page__backgroundColorSecondary landing_page__sectionTitle">
                                APP FEATURES
                            </div>
                            <FeatureTabSection />
                        </section>
                        <div className="landing_page__containerSection">
                            <section className="landing_page__backgroundColorThird landing_page__sectionAboutus">
                                <div className="landing_page__fs22 landing_mobilePage__fs23 landing_page__fontBold landing_page__fontColorSecondary landing_page__backgroundColorSecondary landing_page__sectionTitle" >
                                    ABOUT US
                                </div>
                                <div className="landing_page__fs47 landing_mobilePage__fs64 landing_page__fontBold landing_page__fontColorPrimary landing_page__sectionAboutus_title">
                                    “WE ARE FOOTBALL COMMUNITY MEDIA”
                                </div>
                                <div className="landing_page__fs17 landing_mobilePage__fs25 landing_page__fontRegular landing_page__fontColorPrimary landing_page__sectionAboutus_description">
                                    Founded in 2021, Ufootball is the football community media company based in Malaysia, built for the next generation of fans.
                                    <br /><br />
                                    Our mission is to drive football ecosystems and innovation by bringing the football community together, support the football development in Malaysia to improve Malaysian football.
                                </div>
                                <div className="landing_page__sectionAboutus_mascot">
                                    <img className="w-100" src="/assets/images/other/landingPage/landing_page_mascot.gif" />
                                </div>
                                <div className="landing_page__sectionAboutus_lineBox">
                                    <div className="landing_page__sectionAboutus_lineBox_line">
                                        <div className="landing_page__sectionAboutus_lineBox_lineGreen" />
                                    </div>
                                </div>
                                <div className="landing_page__fs20 landing_mobilePage__fs25 landing_page__fontBold landing_page__fontColorPrimary landing_page__sectionAboutus_lineBox_title">
                                    Ola The Goat.
                                </div>
                                <div className="landing_page__fs12 landing_mobilePage__fs15 landing_page__fontRegular landing_page__fontColorPrimary">
                                    Ufootball’s Mascot.
                                </div>
                            </section>
                        </div>
                        <section className="landing_page__sectionOurPartners landing_page__containerSection">
                            <div className="landing_page__fs22 landing_mobilePage__fs23 landing_page__fontBold landing_page__fontColorSecondary landing_page__backgroundColorSecondary landing_page__sectionTitle">
                                OUR PARTNERS
                            </div>
                            <div className="landing_page__sectionOurPartners_row">
                                <div className="landing_page__sectionOurPartners_row_sponsorLogo">
                                    <img className="w-100" src="/assets/images/other/landingPage/landing_page_logo_1.png?v=2" />
                                </div>
                                <div className="landing_page__sectionOurPartners_row_sponsorLogo">
                                    <img className="w-100" src="/assets/images/other/landingPage/landing_page_logo_2.png" />
                                </div>
                            </div>
                        </section>
                        <div ref={appDownload} className="landing_page__sectionAppDownload_paddingTop">
                            <section className="landing_page__sectionAppDownload landing_page__containerSection">
                                <div className="landing_page__sectionAppDownload_background">
                                    <div className="landing_page__sectionAppDownload_contentBox">
                                        <div className="landing_page__sectionAppDownload_contentBox1" />
                                        <div className="landing_page__sectionAppDownload_contentBox2">
                                            <div className="landing_page__sectionAppDownload_contentBox_logo">
                                                <img className="w-100" src="/assets/images/other/landingPage/landing_page_logo_white.png" />
                                            </div>
                                            <div>
                                                <div>
                                                    <div className="landing_page__fs50 landing_mobilePage__fs58 landing_page__fontBold landing_page__fontColorPrimary text-start">
                                                        DOWNLOAD OUR APP
                                                    </div>
                                                    <div className="landing_page__fs25 landing_mobilePage__fs24 landing_page__fontRegular landing_page__fontColorPrimary text-start">
                                                        Unlock a whole new level of football experience. <br /> Let's <span className="landing_page__fs25 landing_mobilePage__fs24 landing_page__fontSemiBoldItaly landing_page__fontColorPrimary" >GOAL TOGETHER!</span>
                                                    </div>
                                                </div>
                                                <div className="landing_page__sectionAppDownload_contentBox_appDownloadRow">
                                                    <div className="landing_page__sectionAppDownload_contentBox_appDownloadButton">
                                                        <img className="w-100" src="/assets/images/other/landingPage/appstore.png" />
                                                    </div>
                                                    <div className="landing_page__sectionAppDownload_contentBox_appDownloadButton">
                                                        <img className="w-100" src="/assets/images/other/landingPage/googleplay.png" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div />
                                        </div>
                                        <div className="landing_page__sectionAppDownload_contentBox3" />
                                    </div>
                                </div>
                            </section>
                        </div>
                        <section className="landing_page__footer landing_page__containerSection">
                            <div className="landing_page__footerIconRow">
                                <Link href="https://www.facebook.com/UfootballOfficial/" >
                                    <a target="_blank" rel="noopener noreferrer" className="landing_page__footerIcon">
                                        <img className="w-100" src="/assets/images/other/landingPage/landing_page_facebook_icon_white.png" />
                                    </a>
                                </Link>
                                <Link href="https://www.instagram.com/ufootballofficial/" >
                                    <a target="_blank" rel="noopener noreferrer" className="landing_page__footerIcon">
                                        <img className="w-100" src="/assets/images/other/landingPage/landing_page_instagram_icon_white.png" />
                                    </a>
                                </Link>
                                <Link href="https://www.tiktok.com/@ufootballofficial" >
                                    <a target="_blank" rel="noopener noreferrer" className="landing_page__footerIcon">
                                        <img className="w-100" src="/assets/images/other/landingPage/landing_page_tiktok_icon_white.png" />
                                    </a>
                                </Link>
                                <Link href="https://www.youtube.com/@ufootballofficial" >
                                    <a target="_blank" rel="noopener noreferrer" className="landing_page__footerIcon">
                                        <img className="w-100" src="/assets/images/other/landingPage/landing_page_youtube_icon_white.png" />
                                    </a>
                                </Link>
                            </div>
                            <div className="landing_page__fs20 landing_mobilePage__fs22 landing_page__fontRegular landing_page__fontColorPrimary landing_page__footerText">
                                © 2024 Ufootball. All Rights Reserved.
                            </div>
                        </section>
                    </div>
                    <div className="landing_page__footerPosition">
                        <img className="w-100 landing_page__footerBackgroundImage" src="/assets/images/other/landingPage/landing_page_footerGlow.png" />
                        <img className="w-100 landing_mobilePage__footerBackgroundImage" src="/assets/images/other/landingPage/landing_page_m_footerGlow.png" />
                    </div>
                </div>
            </div>
        </>
    )
}
